<template>
   <div class="absolute flex h-12 bg-white rounded-md shadow-lg bottom-10 right-20">
        <div class="flex items-center justify-center px-2 m-0 text-xs font-semibold text-white rounded-md bg-secondary">
            <span>DRMA.JK</span>
        </div>
        <div class="flex flex-col justify-center px-2 text-xs text-left">
            <div class="flex items-center ">
                <div class="flex items-center">
                    <span class="tracking-wider" style="font-weight:bold;"  v-if="this.stock">{{(this.stock.close).toLocaleString()}}</span>
                    <i class="text-sm text-red-600 material-icons" v-if="this.stock.open >  this.stock.close ">arrow_drop_down</i>
                    <i class="text-sm text-green-600 material-icons" v-else>arrow_drop_up</i>
                </div>

                <div class="flex items-center px-1 text-xs">
                    <div class="mr-1 uppercase ">change</div>
                    <div style="font-weight:bold;" >{{ this.stock.change }}</div>
                </div>

                <div class="flex">
                    <span class="px-2 tracking-wide" v-if="this.stock">
                        <div style="font-weight:bold;" >{{this.stock.change_pct.toFixed(2)}}%</div>
                    </span>
                </div>
               
                
                <!-- <span>({{(this.stock.regularMarketChangePercent.toFixed(2))}}%)</span> -->
                
            </div>
            <div style="font-size: 10px;"  class="text-xs text-gray-600 ">
                Delayed by 2 minutes
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
export default {
    
    mounted() {
        this.getStock()

        // console.log(this.stock)
    },
    computed: {
        ...mapState({
            stock: state => state.home.stock ? state.home.stock.results[0]:''
        }),


    },
    methods: {
        async getStock() {
            try {
                await this.$store.dispatch('home/getSTOCK')
                
            } catch (error) {
                console.log(error)
            }
        }
    },
}
</script>
<template>
  <div class="md:w-3/5 mt-6 md:mt-0">
      <div class="border-b border-secondary">
          <h5 class="w-max px-4 py-1 bg-secondary text-white text-sm font-semibold">Introducing PT Dharma Polimetal Tbk</h5>
      </div>
      <div class="my-3 text-left">
          <div class="relative">
               <iframe :src="data.youtube" title="YouTube video player" frameborder="0" class="w-full h-96" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              <!-- <span class="px-2 py-1 font-semibold bg-gray-50 absolute bottom-0">PT Dharma Company Profile</span> -->
          </div>
          <!-- <div class="mt-4 flex flex-row justify-between items-start">
              <div class="w-11/12 lg:mr-0 mr-1">
                  <router-link
                    to="/csr"
                    @click.native="scrollToTop"
                  >
                    <img :src="csrBanner[0].image" style="width:305px;height:220px" alt="">
                    <h3 class="font-semibold">CSR</h3>
                  </router-link>
              </div>
              <div class="w-11/12">
                <router-link
                    to="/tatakelolaperusahaan"
                    @click.native="scrollToTop"
                >
                    <div  
                        :style="{ backgroundImage: `url(${rupstBanner[0].image})` }"
                        class="bg-cover bg-center h-56 w-80"
                    ></div>
                    <img :src="rupstBanner[0].image" style="width:305px;height:220px" alt="">
                    <h3 v-if="lang == 'id'" class="font-semibold">RUPST & RUPSLB</h3>
                    <h3 v-else class="font-semibold">AGMS & EGMS</h3>
                </router-link>
              </div>
          </div> -->
          <div class="flex items-center h-56 mt-4">
                
                <router-link 
                    class="shadow-lg w-3/6 mr-1 flex items-end cursor-pointer  rounded-sm bg-cover  bg-left-top h-full relative" 
                    :style="{ backgroundImage: `url(${csrBanner[0].image})` }"
                    to="/csr"
                    @click.native="scrollToTop"
                >
                    <h3 class="font-semibold absolute -bottom-10">CSR</h3>
                </router-link>

            
                <router-link 
                    class="shadow-lg w-3/6 ml-1 flex items-end cursor-pointer  rounded-sm bg-cover bg-left-top h-full relative" 
                    :style="{ backgroundImage: `url(${rupstBanner[0].image})` }"
                    to="/tatakelolaperusahaan"
                    @click.native="scrollToTop"
                >
                    <h3 v-if="lang == 'id'" class="font-semibold absolute -bottom-10">RUPST & RUPSLB</h3>
                    <h3 v-else class="font-semibold -bottom-10 absolute">AGMS & EGMS</h3>
                </router-link>

            </div>
      </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    mounted() {
		
	},
    computed: {
        ...mapState({
            data: state => state.home.home ? state.home.home.data :'',
            lang: state => state.home.lang ? state.home.lang :'id'
        }),
        rupstBanner(){
			return this.$store.getters['image/imgWtFilter']('manage_banner')
		},
        csrBanner(){
			return this.$store.getters['image/imgWtFilter']('csr_banner')
		}
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        }
	},
}
</script>

<style>

</style>
<template>
	<div class="lg:px-32 md:px-2 font-pop flex flex-wrap md:flex-nowrap justify-center">
		<div class="bg-grays-100 md:w-full w-5/12 p-3 md:p-6 mx-1 my-1 md:my-0 md:mx-2 flex flex-col justify-between cursor-pointer" @click="annual('About')">
			<div class="mb-1 md:justify-start justify-center items-center flex">
				<img src="@/assets/img/Analysis-Result.svg" alt="Analysis-Result" class="w-6 mr-2">
				<span class="font-bold text-base text-left leading-5">History</span>
			</div>
			<div class="hidden md:flex justify-end">
				<h5 class="text-right text-xs font-semibold mr-1">see more</h5>
				<img src="@/assets/img/right-arrow.svg" alt="" class="w-4">
			</div>
		</div>
		<div class="bg-grays-100 md:w-full w-5/12 p-3 md:p-6 mx-1 my-1 md:my-0 md:mx-2 flex flex-col justify-between cursor-pointer" @click="annual('About', 5)">
			<div class="mb-1 md:justify-start justify-center items-center flex">
				<img src="@/assets/img/Presentation.svg" alt="Presentation" class="w-6 mr-2">
				<span class="font-bold text-base text-left leading-5">Milestones</span>
			</div>
			<div class="hidden md:flex justify-end">
				<h5 class="text-right text-xs font-semibold mr-1">see more</h5>
				<img src="@/assets/img/right-arrow.svg" alt="" class="w-4">
			</div>
		</div>
		<div class="bg-grays-100 md:w-full w-5/12 p-3 md:p-6 mx-1 my-1 md:my-0 md:mx-2 flex flex-col justify-between cursor-pointer" @click="annual('HubunganInvestor', 6)">
			<div class="mb-1 md:justify-start justify-center items-center flex">
				<img src="@/assets/img/Report.svg" alt="Report" class="w-6 mr-2">
				<span class="font-bold text-base text-left leading-5">Prospectus</span>
			</div>
			<div class="hidden md:flex justify-end">
				<h5 class="text-right text-xs font-semibold mr-1">see more</h5>
				<img src="@/assets/img/right-arrow.svg" alt="" class="w-4">
			</div>
		</div>
		<div class="bg-grays-100 md:w-full w-5/12 p-3 md:p-6 mx-1 my-1 md:my-0 md:mx-2 flex flex-col justify-between cursor-pointer" @click="annual('Tatakelola', 6)">
			<div class="mb-1 md:justify-start justify-center items-center flex">
				<img src="@/assets/img/Contract.svg" alt="Contract" class="w-6 mr-2">
				<span class="font-bold text-base text-left leading-5">Information Disclosure</span>
			</div>
			<div class="hidden md:flex justify-end">
				<h5 class="text-right text-xs font-semibold mr-1">see more</h5>
				<img src="@/assets/img/right-arrow.svg" alt="" class="w-4">
			</div>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		annual(data, i) {
			let whichTab = i
			let whichPage = data
			// this.$router.push({ name: data })
			this.$router.push({
				name: whichPage,
				params: { data: whichTab}
			});
			window.scrollTo(0,0);
		}
	}
}
</script>

<style>

</style>
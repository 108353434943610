<template>
  <div class="lg:w-1/3 md:w-2/5 w-full mr-4 md:mr-2">
      <div class="border-b border-primary">
          <h5 class="w-max px-4 py-1 bg-primary text-white text-sm font-semibold">Latest News</h5>
      </div>
      <div class="my-3">
          <div v-for="data in getNews" class="mb-2 flex md:items-start cursor-pointer" @click="handleArt(data.hash)">
              <img :src="data.image" :alt="data.image" width="150" height="100" class="md:object-contain object-cover">
              <div class="ml-2 md:ml-1 text-left">
                    <h5 class="text-xs">{{ data.created_at | moment}}</h5>
                    <h3 v-if="lang =='id'" class="text-sm font-medium">{{ data.title }}</h3>
                    <h3 v-else class="text-sm font-medium">{{ data.title_eng }}</h3>
                 
              </div>
          </div>
            <router-link
                to="/berita"
                @click.native="scrollToTop"
                class="w-max py-2 px-4 bg-links text-white font-semibold text-sm flex items-center cursor-pointer"
            >
                {{ $t('news.see') }}
                <img src="@/assets/img/right-arrow-white.svg" alt="" class="ml-2 w-4">
            </router-link>
      </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import { mapState } from 'vuex';

export default {
    data() {
        return {
            latestNews: [
                {
                    title: 'Penyerahan Beasiswa Anak Karyawan Berprestasi Dhama Group 2020',
                    date: 'May 6, 2021',
                    img: 'https://dummyimage.com/150x100/000/fff.png'
                },
                {
                    title: 'Dharma Group Meraih Penghargaan TOP CSR Awards 2021',
                    date: 'May 6, 2021',
                    img: 'https://dummyimage.com/150x100/000/fff.png'
                },
                {
                    title: 'Penyerahan Beasiswa Anak Karyawan Berprestasi Dhama Group 2020',
                    date: 'May 6, 2021',
                    img: 'https://dummyimage.com/150x100/000/fff.png'
                },
                {
                    title: 'HUT DP ke-32 : Gratitude in New Era',
                    date: 'May 6, 2021',
                    img: 'https://dummyimage.com/150x100/000/fff.png'
                },
                {
                    title: 'Mentri Kesehatan Terima Hibah Dharcov23S',
                    date: 'May 6, 2021',
                    img: 'https://dummyimage.com/150x100/000/fff.png'
                },
                {
                    title: 'Konvensi Improvement Dharma XXV',
                    date: 'May 6, 2021',
                    img: 'https://dummyimage.com/150x100/000/fff.png'
                }
            ],
            getNews: null
        }
    },
    mounted() {
        axios
        .get('https://api.dharmagroup.co.id/api/article/datatable/news?perpage=6&page=1', {
            'headers': { 'Authorization': 'Bearer 71063a7eab248181eafe665bf4ae346f-sFqsGThLIgEL9Kvjo0NZInsTou2NMoexYTnPwVjXAgV1K2scDuW58jBBy53'} })
        .then((res) => {
            // console.log(res);
            this.getNews = res.data.data
        })
    },
    filters: {
        moment: function (date) {
            return moment(date).format('DD MMM YYYY');
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        async handleArt(hash){
            try {
                const payload = {
                    hash: hash
                }
                await this.$store.dispatch('news/getViewArt',payload)
                this.$router.push(
                    { 
                        name: "detail",
                        params: {id: hash}
                    }
                )
                window.scrollTo(0,0);

            } catch (error) {
                console.log(error)
                    this.$router.push(
                    { 
                        path: "/404"
                    }
                )
            }
            
            
        }
    },
    computed: {
        ...mapState({
            lang: state => state.home.lang ? state.home.lang :'id'
        }),
    }
}
</script>

<style>

</style>
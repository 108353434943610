<template>
	<div class="home">
		<div class="relative">
			<carousel
				:nav = true
				:centerMode = true
				:autoPlay = true
				:playSpeed = 8000
				:dtImg = banner
				class="mb-10"
			/>
			<Stock v-if="stock !== null" />
		</div>
		<SqNav />
		<div class="max-w-screen-xl mx-auto mt-8 lg:px-32 px-2 flex md:flex-row flex-col justify-between">
			<LatestNews />
			<IntroducingDharma />
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SqNav from "@/components/SqNav.vue";
import LatestNews from "@/components/home/LatestNews.vue";
import IntroducingDharma from "@/components/home/IntroducingDharma.vue";
import carousel from "@/components/carousel.vue";
import Stock from "@/components/Stock.vue";
import axios from 'axios'
import { mapState } from 'vuex';

export default {
	name: "Home",
	components: {
		SqNav,
		carousel,
		LatestNews,
    	IntroducingDharma,
		Stock
	},
	data(){
		return {
			datIdx:null
		}
	},

	mounted () {
		this.show()

		// this.$nextTick(function () {
        //     window.setInterval(() => {
        //        this.getIdx()
		// 	//    console.log("MANTEP")
        //     },300000);
        // })

		
	},
	computed: {
		banner(){
			return this.$store.getters['image/imgWtFilter']('home_banner')
		},

		...mapState({
            stock: state => state.home.stock ? state.home.stock:false
        }),
	},
	methods: {
		show(){
			 this.$store.commit('home/SET_MODAL', true)
		}
	},

}
</script>

<style>
	
</style>